/*
  ********************************************************************************************************************
    ANALISI SU DATI PROVENIENTI DA TRASFEREIMENTO SU CLOUD DI ARCHIVI FATA/MAGO ATTIVI
    IL TRASFERIMENTO E' EFFETTUATO TRAMITE MODULO "COLLECTOR" INSTALLATO SULLA MACCHINA DOVE RISIEDE IL DATABASE .MDB
  *********************************************************************************************************************
*/
import collector from './collectorService'

const getMovimentiReport = async (base, apikey, filter) => {
  const startkey = creaStartKey(apikey, filter)
  const endkey = creaEndKey(apikey, filter)
  return collector.getMovimentiReport(base, startkey, endkey)
}

const getMovimentiMapReduceCategoria = async (base, apikey, filter, tipo) => {
  const startkey = filter.start // sistemare  creaStartKey(apikey, filter)
  const endkey = filter.end // sistemare creaEndKey(apikey, filter)
  const movimenti = await collector.getMovimentiMapReduceCategoria(base, startkey, endkey)

  const filtroTipo = movimenti.filter(x => x.key.substring(8, 11) === `_${tipo}_`)
  return filtroTipo.map(x => { return { key: x.key.slice(11), value: x.value }})
}

function creaStartKey(apikey, filter) {
  const parametri = []

  parametri.push(apikey)
  parametri.push(filter.start)
  if (filter.puntoVendita) parametri.push(filter.puntoVendita)

  return parametri.join('|')
}

function creaEndKey(apikey, filter) {
  const parametri = []

  parametri.push(apikey)
  parametri.push(filter.end)
  if (filter.puntoVendita) parametri.push(filter.puntoVendita)

  return parametri.join('|')
}

export default {
  getMovimentiReport,
  getMovimentiMapReduceCategoria
}
