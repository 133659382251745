'use strict'
/*
  ********************************************************************************************************************
    ANALISI SU DATI PROVENIENTI DA TRASFEREIMENTO SU CLOUD DI ARCHIVI FATA/MAGO ATTIVI
    IL TRASFERIMENTO E' EFFETTUATO TRAMITE MODULO "COLLECTOR" INSTALLATO SULLA MACCHINA DOVE RISIEDE IL DATABASE .MDB
  *********************************************************************************************************************
*/
import PouchDB from 'pouchdb';
PouchDB.plugin(require('pouchdb-find')); // npm install --save pouchdb-find

const service = process.env.VUE_APP_service_licenze || ''

const getMovimentiReport = async (base, startkey, endkey, limit = 0) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'collector' // se necessario passare per utente

  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return

    let opz = { include_docs: true, startkey: startkey, endkey: endkey }
    if (limit > 0) {
      opz.limit = limit
    }
    const result = await db.allDocs(opz);
    await db.close()
    const elenco = result.rows.map(x => x.doc).filter(x => x && !x._id.startsWith('_'));
    return elenco
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

const getMovimentiMapReduceCategoria = async (base, startkey, endkey, limit = 0) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'collector' // se necessario passare per utente

  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    // eventualmente aggiungere apikey su parte iniziale di emit, prima della data
    var ddoc = {
      _id: '_design/categoria',
      views: {
        by_date: {
          map: `function (doc) {
            if (doc.Categoria1) {
              const data = doc.DatMag.substring(0, 4) + doc.DatMag.substring(5, 7) + doc.DatMag.substring(8, 10) 
              emit(data + '_Q_' + doc.Categoria1, doc.QtaMag); // [doc.riferimento.id, doc.riferimento.tipo, doc.magazzino, doc.causale]
              emit(data + '_P_' + doc.Categoria1, doc.PrezzoVendita);
            }
          }`,
          reduce: '_sum'
        }
      }
    }
    try {
      const creazione = await db.put(ddoc)
    } catch (err) {
      if (err.name !== 'conflict') {
        throw err;
      }
      // ignore if doc already exists
    }
    let options = {
      group: true,
      reduce: true,
      // group_level: 4,
      include_docs: false,
      startkey: startkey,
      endkey: endkey
    }
    if (limit > 0) {
      opz.limit = limit
    }      
    var result = await db.query('categoria/by_date', options)
    await db.close()
    const elenco = result.rows
    return elenco
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

export default {
  getMovimentiReport,
  getMovimentiMapReduceCategoria
}
